
import { mapGetters } from 'vuex'
export default {
    name: 'PromotionComponent',
    data: () => ({
        claimPromotion: null,
        valid: true,
        tab: 0,
        selection: 0,
        promotionTypes: [
            'all promotions',
            'sports',
            'live casino',
            'cards',
            'refer a friend',
            'slot',
            'tournament',
            'super surprise',
        ],
    }),
    computed: {
        ...mapGetters({
            promotions: 'promotion/promotions',
        }),
        loading() {
            return this.$store.state.promotion.loading
        },
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
    },
    mounted() {
        this.$store.dispatch('promotion/promotions')
    },
    methods: {
        getTextWithCurrentLanguage(data) {
            const text = data[this.handleCookie('wl_locale')]
            if (text) {
                return text
            }
            return data['en-US']
        },
        onClaim() {
            if (this.claimPromotion) {
                if (this.$cookie.get('token')) {
                    this.$store.dispatch(
                        'promotion/promotionCode',
                        this.claimPromotion
                    )
                } else {
                    this.$router.push('/login')
                }
            }
        },
    },
}
