
import { mapState } from 'vuex'
export default {
    data() {
        return {
            model: null,
            infoItems: [
                {
                    icon: 'mdi-account',
                    text: 'my profile',
                    key: 'profile',
                    to: '/player/profile',
                },
                {
                    icon: 'mdi-book',
                    text: 'bank details',
                    key: 'bank-details',
                    to: '/player/banks',
                },
                {
                    icon: 'mdi-message-text-fast-outline',
                    text: 'messages',
                    actions: 10,
                    key: 'messages',
                    to: '/player/messages',
                },
                {
                    icon: 'mdi-history',
                    text: 'history',
                    key: 'history',
                    to: '/history/transaction',
                },
                {
                    icon: 'mdi-lock',
                    text: 'reset password',
                    key: 'reset-password',
                    to: '/reset-password',
                },
                {
                    icon: 'mdi-gift-outline',
                    text: 'my bonuses',
                    key: 'bonus',
                    to: '/my-bonus',
                },
                {
                    icon: 'mdi-account-group-outline',
                    text: 'earn and win',
                    key: 'refer-friend',
                    to: '/refer-friend',
                },
            ],
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
    },
}
