
export default {
    data() {
        return {
            paymentProvider: 'credit_card',
            paymentProviders: [
                {
                    id: 1,
                    provider: 'credit_card',
                    name: 'Credit Card',
                    image: 'https://png.pngitem.com/pimgs/s/116-1162232_credit-card-atm-card-vector-png-transparent-png.png',
                },
                {
                    id: 1,
                    provider: 'skrill',
                    name: 'Skrill',
                    image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Skrill_logo.svg/1200px-Skrill_logo.svg.png',
                },
                {
                    id: 1,
                    provider: 'neteller',
                    name: 'Neteller',
                    image: 'https://s3-eu-west-1.amazonaws.com/tpd/logos/46acf15c000064000500929e/0x0.png',
                },
                {
                    id: 1,
                    provider: 'momopay',
                    name: 'MOMO Pay',
                    image: 'https://www.ppro.com/wp-content/uploads/2021/06/MoMo_Logo.png',
                },
            ],
        }
    },
    methods: {
        timeFormat(value) {
            return ('0' + parseInt(value || '00')).slice(-2)
        },
    },
}
