
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'SliderComponent',
    mixins: [cms, playGames],
    data() {
        return {
            interval: 1000,
        }
    },
    computed: {
        ...mapGetters({
            mobileSlider: 'cms/mobileSlider',
        }),
    },
    methods: {
        onSlideChange(slideNumber) {
            this.interval = 2 * 1000
        },
    },
}
