
export default {
    data() {
        return {
            steppers: 1,
            selectedBank: 1,
            remainingTime: {
                minutes: 14,
                seconds: 5,
            },
        }
    },
    mounted() {},
    methods: {
        timeFormat(value) {
            return ('0' + parseInt(value || '00')).slice(-2)
        },
    },
}
