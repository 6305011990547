
export default {
    data() {
        return {
            tab: null,
            dialog: false,
        }
    },
    computed: {
        headers() {
            return [
                { text: 'Time', value: 'time' },
                { text: 'Number of friends', value: 'time' },
                { text: 'Total Turnover', value: 'time' },
                { text: 'Referral Rate', value: 'rate' },
                { text: 'Commission', value: 'commission' },
            ]
        },
    },
}
