export default {
	data() {
		return {
			clientName: null,
			domainName: null,
			pagination: {
				clickable: true,
				type: 'bullets',
				dynamicBullets: true,
				renderBullet: (index, className) => {
					return `<span class="${className}">${index + 1}</span>`
				},
			},
			breakpoints: {
				600: {
					slidesPerView: 1,
					slidesPerGroup: 1,
				},
				900: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				1100: {
					slidesPerView: 3,
					slidesPerGroup: 3,
				},
				1300: {
					slidesPerView: 4,
					slidesPerGroup: 4,
				},
			},
		}
	},

	head() {
		return {
			title: `Download on the App Store - ${this.$config.DOMAIN?.split('.')[0]
				}`,
			meta: [
				{
					hid: 'og:url',
					name: 'og:url',
					content: `https://www.${this.$config.DOMAIN}/download/ios`, // This can be overridden by page-level meta tags.
				},
			],
		}
	},

	computed: {
		slideImages() {
			const language = this.handleCookie('wl_locale')

			const country = language === "th-TH"
				|| language === "zh-CN" ? language : 'en-EN'

			return [
				{ image: `/download-slider/${this.operatingSystem}/${country}/one.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/two.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/three.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/four.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/five.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/six.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/seven.png` },
				{ image: `/download-slider/${this.operatingSystem}/${country}/eight.png` },
			]
		},
	},

	mounted() {
		this.domainName = this.$config.DOMAIN.split('.')[0]
		this.clientName = this.domainName.charAt(0).toUpperCase() + this.domainName.slice(1);
	},

	methods: {
		isAndroidOrIOS(os) {
			if (os === 'ios') {
				return 'mobileconfig'
			}
			else {
				return 'apk'
			}
		}
	},
}
