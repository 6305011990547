
export default {
    name: 'FilterForm',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
