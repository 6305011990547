
export default {
    data() {
        return {
            headers: [
                { text: 'Transaction Id', value: 'time' },
                { text: 'Date Time', value: 'time' },
                { text: 'Amount', value: 'time' },
                { text: 'Transition Type', value: 'time' },
                { text: 'Status', value: 'time' },
            ],
        }
    },
}
