
export default {
    name: 'CardsComponent',
    data: () => ({
        submenu: {},
    }),
    computed: {
        player() {
            return this.$store.state.player.player.length
                ? this.$store.state.player.player
                : this.$cookie.get('player')
        },
        menubar() {
            return this.$store.state.settings.menu
        },
        loading() {
            return this.$store.state.settings.loading
        },
    },
    watch: {
        menubar(value) {
            if (value && value.length > 0) {
                this.onCardClick(value[0])
            }
        },
    },
    mounted() {
        if (this.menubar && this.menubar.length > 0) {
            this.onCardClick(this.menubar[0])
        }
    },
    methods: {
        onCardClick(menu) {
            this.submenu = menu
        },
        menuClicked(provider) {
            if (!this.$cookie.get('token')) {
                this.$store.dispatch('settings/toggleIsLogin')
                this.$router.push('/login')
                return
            }
            if (!this.$cookie.get('player').has_bank_account) {
                this.$router.push('/player/profile')
                this.$toast.error('Please add a bank account')
                return
            }
            const {
                game_id: gameId,
                game_provider_uuid: gameProviderId,
                product_id: productId,
                portfolio_uuid: portfolioUuid,
            } = provider

            let link = `/redirection?platform=${
                this.isMobile ? 'phone' : 'desktop'
            }&device=${this.isMobile ? 'M' : 'D'}`

            if (gameId) link += `&game_id=${gameId}`
            if (gameProviderId) link += `&game_provider_uuid=${gameProviderId}`
            if (productId) link += `&product_id=${productId}`
            if (portfolioUuid) link += `&portfolio_uuid=${portfolioUuid}`

            window.open(link, '_blank')
        },
    },
}
