
import { mapState } from 'vuex'
export default {
    data() {
        return {
            socialConnectList: [
                { id: 'kayengxiong.com', platform: 'facebook' },
            ],
            phone: null,
        }
    },
    computed: {
        currencies() {
            return ['Dong', 'Kip', 'Baht']
        },
        platforms() {
            return ['facebook', 'whatsapp', 'instagram', 'linkedin']
        },
        ...mapState({
            loadingPlayer: (state) => state.player.loading,
            player: (state) => state.player.player,
        }),
    },
    watch: {
        player(value) {
            this.phone = value.phone
        },
    },
}
