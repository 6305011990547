
import { mapState } from 'vuex'
export default {
    data() {
        return {
            addBankAccountModel: false,
            addCryptWalletModel: false,
            hasBank: false,
        }
    },
    computed: {
        ...mapState({
            loadingPlayer: (state) => state.player.loading,
            player: (state) => state.player.player,
            accounts: (state) => state.banks.accounts,
            loadingBankAccount: (state) => state.banks.loading,
            banks: (state) => state.banks.banks,
            createdBankAccount: (state) => state.banks.created,
            cardHolderName: (state) => state.banks.cardHolderName,
        }),
    },
}
