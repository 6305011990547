import { render, staticRenderFns } from "./DepositPage.vue?vue&type=template&id=5bc36ed8"
import script from "./DepositPage.vue?vue&type=script&lang=js"
export * from "./DepositPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme1DesktopProfilesDepositsBankTransfer: require('/var/app/components/Theme1/Desktop/Profiles/Deposits/BankTransfer.vue').default,Theme1DesktopProfilesDepositsCryptoPayment: require('/var/app/components/Theme1/Desktop/Profiles/Deposits/CryptoPayment.vue').default,Theme1DesktopProfilesDepositsOnlineBank: require('/var/app/components/Theme1/Desktop/Profiles/Deposits/OnlineBank.vue').default})
