
import { mapState } from 'vuex'
import DataTable from '~/mixins/dataTable'
export default {
    name: 'WithdrawalComponent',
    mixins: [DataTable],
    middleware: ['checkAuth'],
    data: () => ({
        api: 'player/withdrawals',
        withdrawalType: 'bank',
    }),
    computed: {
        ...mapState({
            withdrawals: (state) => state.player.withdrawals,
            player: (state) => state.player.player,
        }),
        withdrawalTypeData() {
            return [
                {
                    text: 'Bank transfer',
                    value: 'bank',
                },
                {
                    text: 'Crypto transfer',
                    value: 'crypto',
                },
            ]
        },
        bonusArray() {
            return [
                { value: '', text: this.tl('all') },
                { value: true, text: this.tl('yes') },
                { value: false, text: this.tl('no') },
            ]
        },
        headers() {
            return [
                { text: this.tl('withdrawalRequestTime'), value: 'created_at' },
                {
                    text: this.tl('withdrawalAmount'),
                    value: 'withdrawal_amount',
                },
                {
                    text: this.tl('balanceBeforeWithdraw'),
                    value: 'balance_before',
                },
                {
                    text: this.tl('balanceAfterWithdraw'),
                    value: 'balance_after',
                },
                { text: this.tl('status'), value: 'withdrawal_status' },
            ]
        },
    },
    mounted() {
        this.customFilters = {
            start_date: '2021-01-01 00:00:00',
            end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        }
    },
}
