
import cms from '~/mixins/common/cms'
export default {
    name: 'GameSubmenu',
    mixins: [cms],
    props: {
        submenu: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data: () => {
        return {
            hover: false,
            submenuSize: 'auto',
            windowSize: {
                x: 0,
                y: 0,
            },
            failed_image: false,
        }
    },
    computed: {
        hoveredSubmenuIndex() {
            return this.$store.state.cms.menus.hoveredSubmenuIndex
        },
        isMobile() {
            return this.$store.getters['settings/isMobile']
        },
    },
    watch: {
        'windowSize.x'(value) {
            if (value > 1268 && value < 1700) {
                // ? window width size is less than 1268, called phone screen
                this.submenuSize = '120px'
            } else if (value > 2000 && value <= 1700) {
                // ? window which has width's size is in range (2000, 1700), desktop screen but medium screen
                this.submenuSize = '150px'
            } else {
                this.submenuSize = '180px'
            }
        },
    },
    methods: {
        menuMouseOver() {
            this.$store.commit(
                'cms/menus/setHoveredSubmenuIndex',
                this.submenu.index
            )
        },
        menuMouseLeave() {
            this.$store.commit('cms/menus/setHoveredSubmenuIndex', null)
        },

        menuClicked() {
            if (!this.$cookie.get('token')) {
                this.$router.push('/login')
                return
            }
            const {
                game_id: gameId,
                product_id: productId,
                portfolio_uuid: portfolioUuid,
                component_uuid: componentUUid,
                provider_id: providerId,
                game_provider_uuid: gameProviderUUid,
            } = this.submenu.provider

            let link = `/redirection?platform=${
                this.isMobile ? 'phone' : 'desktop'
            }&device=${this.isMobile ? 'M' : 'D'}`

            if (gameId) link += `&game_id=${gameId}`
            if (productId) link += `&product_id=${productId}`
            if (portfolioUuid) link += `&portfolio_uuid=${portfolioUuid}`
            if (componentUUid) link += `&portfolio_uuid=${componentUUid}`
            if (providerId) link += `&game_provider_uuid=${providerId}`
            if (gameProviderUUid)
                link += `&game_provider_uuid=${gameProviderUUid}`

            window.open(link, '_blank')
        },
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        onImageError() {
            this.failed_image = true
        },
    },
}
